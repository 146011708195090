<template>
  <div>
    <a-modal
      v-model="visible"
      width="768px"
      :confirmLoading="loading"
      :destroyOnClose="true"
      :maskClosable="false"
      centered
      @cancel="cancel"
      @ok="confirm"
    >
      <div slot="title">{{ form.id ? `编辑产品 - ${form.name}` : "新增产品" }}</div>
      <div>
        <a-form-model
          ref="form"
          :model="form"
          :rules="rules"
          :label-col="{ span: 4, md: 6 }"
          :wrapper-col="{ span: 16, md: 18 }"
        >
          <a-row gutter="16">
            <a-col :span="24" :md="12">
              <a-form-model-item prop="number" label="产品编号">
                <a-input v-model="form.number" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="name" label="产品名称">
                <a-input v-model="form.name" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="barcode" label="条码">
                <a-input v-model="form.barcode" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="client" label="客户">
                <ClientSelect v-model="form.client" :defaultItem="form.client_item" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="category_set" label="分类">
                <CategoryFormSelect v-model="form.category_set" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="unit" label="单位">
                <a-input v-model="form.unit" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="unit_price" label="单价">
                <a-input-number v-model="form.unit_price" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="length" label="长度">
                <a-input-number v-model="form.length" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="width" label="宽度">
                <a-input-number v-model="form.width" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="height" label="高度">
                <a-input-number v-model="form.height" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="volume" label="体积">
                <a-input-number v-model="form.volume" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="weight" label="重量">
                <a-input-number v-model="form.weight" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="spec" label="规格">
                <a-input v-model="form.spec" />
              </a-form-model-item>
            </a-col>
            <!-- <a-col :span="24" :md="12">
              <a-form-model-item prop="enable_shelf_life" label="启用保质期">
                <a-select v-model="form.enable_shelf_life" style="width: 100%">
                  <a-select-option :value="true">开启</a-select-option>
                  <a-select-option :value="false">关闭</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12" v-if="form.enable_shelf_life">
              <a-form-model-item prop="shelf_life_days" label="保质期(天)">
                <a-input-number v-model="form.shelf_life_days" :min="0" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12" v-if="form.enable_shelf_life">
              <a-form-model-item prop="shelf_life_warning_days" label="预警天数">
                <a-input-number v-model="form.shelf_life_warning_days" :min="0" style="width: 100%" />
              </a-form-model-item>
            </a-col> -->
            <a-col :span="24" :md="12">
              <a-form-model-item prop="enable_inventory_warning" label="库存预警">
                <a-select v-model="form.enable_inventory_warning" style="width: 100%">
                  <a-select-option :value="true">开启</a-select-option>
                  <a-select-option :value="false">关闭</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="is_active" label="状态">
                <a-select v-model="form.is_active" style="width: 100%">
                  <a-select-option :value="true">激活</a-select-option>
                  <a-select-option :value="false">冻结</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12" v-if="form.enable_inventory_warning">
              <a-form-model-item prop="inventory_lower" label="库存下限">
                <a-input-number v-model="form.inventory_lower" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12" v-if="form.enable_inventory_warning">
              <a-form-model-item prop="inventory_upper" label="库存上限">
                <a-input-number v-model="form.inventory_upper" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item prop="remark" label="备注" :label-col="{ span: 4, md: 3 }" :wrapper-col="{ span: 16, md: 21 }">
                <a-textarea v-model="form.remark" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { materialCreate, materialUpdate } from "@/api/material";
import rules from "./rules.js";

export default {
  components: {
    ClientSelect: () => import("@/components/ClientSelect/"),
    CategoryFormSelect: () => import("@/components/CategoryFormSelect/"),
  },
  props: ["visible", "form"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      rules,
      loading: false,
    };
  },
  methods: {
    confirm() {
      if (
        this.form.inventory_upper != undefined &&
        this.form.inventory_lower != undefined &&
        this.form.inventory_upper < this.form.inventory_lower
      ) {
        this.$message.error("库存下限不能大于库存上限");
        return;
      }

      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let func = this.form.id ? materialUpdate : materialCreate;
          func(this.form)
            .then((data) => {
              this.$message.success(this.form.id ? "修改成功" : "新增成功");
              this.$emit(this.form.id ? "update" : "create", data);
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },
  },
};
</script>

<style scoped></style>
