export default {
  number: [
    { required: true, message: '请输入产品编号', trigger: 'change' },
    { max: 32, message: '超出最大长度 (32)', trigger: 'change' },
  ],
  name: [
    { required: true, message: '请输入产品名称', trigger: 'change' },
    { max: 64, message: '超出最大长度 (64)', trigger: 'change' },
  ],
  barcode: [{ max: 32, message: '超出最大长度 (32)', trigger: 'change' }],
  client: [
    { required: true, message: '请选择客户', trigger: 'change' },
  ],
  remark: [{ max: 256, message: '超出最大长度 (256)', trigger: 'change' }],
}